import React from 'react';
import {Plan} from '../../database/schemas/plan';
import moment from 'moment';
import ImageIcon from '../general/ImageIcon';
import {Card, Title} from '../../styled-components/Card';
import {Row} from '../../styled-components/Row';
import {Field, Label} from '../../styled-components/Field';
import {Light} from '../../styled-components/Text';
import {plansDir} from '../../hooks/usePlans';
import styled from 'styled-components/native';
import {Text} from 'react-native';

const PlanInfo = styled(Field)`
  max-width: 85%;
`;

const Preview = styled(Text)`
  color: rgb(254, 0, 0);
  font-weight: bold;
`;

interface PlanListItemProps {
  plan: Plan;
}

const PlanListItem = ({plan}: PlanListItemProps) => {
  const version = (plan.version || 0).toString().match(/^-?\d+(?:\.\d{0,3})?/);
  return (
    <Card>
      <Row>
        <PlanInfo>
          <Label>
            {plan.planStatus === 'Draft' && (
              <>
                <Preview>Preview</Preview>
                {'  •  '}
              </>
            )}
            {moment(plan.publishedDate).format('MMM d, YYYY')}
            {'  •  '}V.
            {version && version.length ? version[0] : 0}
          </Label>
          <Title>{plan.name}</Title>
          {plan.description && <Light>{plan.description}</Light>}
        </PlanInfo>
        <ImageIcon
          source={{
            uri: `file://${plansDir}/${plan.planID}/icon.png`,
          }}
        />
      </Row>
    </Card>
  );
};

export default PlanListItem;
