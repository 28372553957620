import React, {useEffect} from 'react';
import styled from 'styled-components/native';
import {Row} from '../../styled-components/Row';
import {View, Text} from 'react-native';
import {Card} from '../../styled-components/Card';
import Icon from 'react-native-vector-icons/FontAwesome';
import {NotificationType} from '../../hooks/useNotifications';

const NotificationWrapper = styled(View)`
  position: absolute;
  bottom: 100px;
  width: 100%;
  padding: 20px;
  z-index: 1;
  elevation: 3;
`;

const NotificationCard = styled(Card)`
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 460px;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  elevation: 3;
`;

interface IconWrapperProps {
  background: string;
}

const IconWrapper = styled(View)<IconWrapperProps>`
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: ${(props) => props.background};
`;

const Message = styled(Text)`
  flex-grow: 1;
  font-size: 16px;
  margin-left: 15px;
`;

interface NotificationProps {
  type: NotificationType;
  message: string;
  onTimeout: () => void;
}

const Notification = ({type, message, onTimeout}: NotificationProps) => {
  useEffect(() => {
    setTimeout(() => onTimeout(), 2000);
  });

  return (
    <NotificationWrapper>
      <NotificationCard>
        <Row>
          <IconWrapper
            background={
              type === 'alert' ? 'rgb(254, 0, 0)' : 'rgb(0, 197, 96)'
            }>
            <Icon
              name={type === 'alert' ? 'exclamation' : 'check'}
              size={16}
              color="white"
            />
          </IconWrapper>
          <Message>{message}</Message>
        </Row>
      </NotificationCard>
    </NotificationWrapper>
  );
};

export default Notification;
