import React, {useContext, useState} from 'react';
import {View} from 'react-native';
import PlansContext from '../../../contexts/PlansContext';
import {
  Contents,
  ExpandableContent as ExpandableContentType,
} from '../../../database/schemas/content';
import styled from 'styled-components/native';
import Screen from '../../../styled-components/Screen';
import WebView from '../../general/WebView';
import {
  Background,
  IconWrapper,
  ListContentItem,
  TextWrapper,
} from '../../../styled-components/ListItem';
import {contentSignature, contentUrl} from '../../../api';

type ExpandableProps = {
  expanded: boolean;
};

const ListContentItemWrapper = styled(View)<ExpandableProps>`
  width: 100%;
  flex-grow: ${(props) => (props.expanded ? '1' : '0')};
`;

interface ExpandableContentProps {
  planId: string;
  contentId: string;
}

const ExpandableContent = ({planId, contentId}: ExpandableContentProps) => {
  const [expanded, setExpanded] = useState('');
  const context = useContext(PlansContext);
  const contents = context!.doa.getDocument<Contents>('Contents', contentId);

  return (
    <Screen>
      {contents!.expandableContents.map((content: ExpandableContentType) => (
        <ListContentItemWrapper
          key={content.expandableContentID}
          expanded={expanded === content.expandableContentID}>
          <ListContentItem
            key={content.expandableContentID}
            onPress={() =>
              setExpanded(
                expanded === content.expandableContentID
                  ? ''
                  : content.expandableContentID,
              )
            }>
            <Background background={content.backgroundColor || 'black'} />
            <TextWrapper>{content.title}</TextWrapper>
            <IconWrapper
              background={content.backgroundColor || 'black'}
              name={expanded === content.expandableContentID ? 'remove' : 'add'}
              size={24}
              color="white"
            />
          </ListContentItem>
          {expanded === content.expandableContentID && (
            <WebView
              source={{
                uri: `${contentUrl}/${planId}/${contentId}/htmls/${content.expandableContentHTMLFile!}${contentSignature}`,
              }}
            />
          )}
        </ListContentItemWrapper>
      ))}
    </Screen>
  );
};

export default ExpandableContent;
