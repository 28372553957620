import React from 'react';
import {Plan} from '../../database/schemas/plan';
import {Card} from '../../styled-components/Card';
import {Row} from '../../styled-components/Row';
import {Field, Label, Value} from '../../styled-components/Field';
import ImageIcon from '../general/ImageIcon';
import {plansDir} from '../../hooks/usePlans';
import styled from 'styled-components/native';

const PlanInfo = styled(Field)`
  max-width: 85%;
`;

interface PlanListItemProps {
  plan: Plan;
}

const PlanListItem = ({plan}: PlanListItemProps) => (
  <Card>
    <Row>
      <PlanInfo>
        <Label>
          {plan.contacts.length ? plan.contacts.length : 'No'} Contacts
        </Label>
        <Value>{plan.name}</Value>
      </PlanInfo>
      <ImageIcon
        source={{
          uri: `file://${plansDir}/${plan.planID}/icon.png`,
        }}
      />
    </Row>
  </Card>
);

export default PlanListItem;
