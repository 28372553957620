import React, {PropsWithChildren, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {formToEmail, injectFormValues} from '../../utils/forms';
import {maxWidth} from '../../styled-components/windowIsWeb';

interface ContentData {
  contentBody: HTMLElement;
  id: string;
  parsedContent: Document;
  source: string;
}

interface WebViewProps {
  source?: any;
  onLoad?: () => void;
  paddingBottom?: string;
}

const ScrollWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ContentWrapper = styled.div<WebViewProps>`
  display: flex;
  justify-content: center;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : 0)};
`;

const Content = styled.div`
  max-width: ${maxWidth.container};
`;

const simplifyContentId = (id: string): string => {
  return id
    ? id.replace(/(file:\/\/\/plans\/)/gi, '').replace(/(.html)/gi, '')
    : '';
};

const simplifySerializedContent = (serialized: string): string => {
  return serialized
    .replace(
      /(<div xmlns="http:\/\/www.w3.org\/1999\/xhtml"><html><head><\/head><body>)/gi,
      '',
    )
    .replace(/(\n\n)/gi, '')
    .replace(/(<\/body><\/html><\/div>)/gi, '');
};

const injectFontStyles = ({parsedContent}: ContentData): Document => {
  const styleSheet = parsedContent.createElement('style');
  styleSheet.innerText = `* {
    font-family: sans-serif;
    line-height: 1.3;
  }`;
  parsedContent.body.appendChild(styleSheet);
  return parsedContent;
};

const injectFormActionFunctionality = ({
  id,
  contentBody,
  parsedContent,
}: ContentData): Document => {
  const form = parsedContent.querySelector('form');
  if (form) {
    // helper function returns value of HTMLInputElement
    const getInputValue = (selector: string) =>
      (contentBody.querySelector(selector) as HTMLInputElement).value;
    // add default save and submit functionality
    form.onsubmit = (e: Event) => {
      e.preventDefault();
      const action = e.submitter.innerText;
      const data = Object.fromEntries(new FormData(form).entries());
      const email = getInputValue('[name=emailAddresses]');
      const subject = getInputValue('[name=subject]');
      const stringForm = new window.XMLSerializer().serializeToString(contentBody);

      // handle form events
      if (action === 'Save') {
        const serialized = injectFormValues(stringForm, data);
        const simplifiedContent = simplifySerializedContent(serialized);
        window.localStorage.setItem(id, simplifiedContent);

      } else if (action === 'Submit') {
        const body = formToEmail(stringForm, data);
        window.open(`mailto:${email}?subject=${subject}&html-body=${encodeURIComponent(body)}`);
      }
    };
    // capture input change events to explicitly define current
    // dom state of boolean inputs to serializeToString later.
    form.oninput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (target) {
        if (target.type === 'checkbox') {
          if (target.hasAttribute('checked')) target.removeAttribute('checked');
          else if (!target.hasAttribute('checked')) target.setAttribute('checked', 'true');
        }
        if (target.type === 'radio') {
          const radioGroup = form.querySelectorAll(`input[name='${target.name}']`);
          for (const radioInput of radioGroup) radioInput.removeAttribute('checked');
          target.setAttribute('checked', 'true');
        }
      }
    };
  }
  return parsedContent;
};


const getContentHtml = (contentData: ContentData): HTMLHtmlElement | null => {
  let {parsedContent} = contentData;
  // inject code to expand parsing functionality
  parsedContent = injectFormActionFunctionality(contentData)
  // force sans-serif font for content
  parsedContent = injectFontStyles(contentData);
  // can't append #document object directly to contentBody,
  // so return html as "top level" instead
  return parsedContent.querySelector('html');
};

export const WebView = ({
  source: sourceRoot,
  paddingBottom,
}: PropsWithChildren<WebViewProps>) => {
  let contentBody = useRef<HTMLElement | null>().current;
  const contentId = simplifyContentId(sourceRoot.uri);
  let savedContent = window.localStorage.getItem(contentId);

  useEffect(() => {
    // if react is aware of the render container
    if (contentBody) {
      // prevent infinite appending
      contentBody.innerHTML = '';

      const appendContent = (html: string) => {
        if (contentBody) {
          const contentData: ContentData = {
            contentBody,
            id: contentId,
            parsedContent: new window.DOMParser().parseFromString(
              html,
              'text/html',
            ),
            source: html,
          };

          const sourceContent = getContentHtml(contentData);
          if (sourceContent) {
            contentBody.append(sourceContent);
          }
        }
      };

      // If saved source is found in localstorage
      // it's assumed to be a string of a simple html page (about.html, t&c.html)
      // otherwise source is an object with a url, fetch from server.
      savedContent
        ? appendContent(savedContent)
        : typeof sourceRoot === 'string'
        ? appendContent(sourceRoot)
        : sourceRoot.uri
        ? fetch(sourceRoot.uri)
            .then((resp) => resp.text())
            .then((resp) => appendContent(resp))
        : '';
    }
  }, [contentBody]);

  return (
    <ScrollWrapper>
      <ContentWrapper paddingBottom={paddingBottom}>
        <Content>
          <div ref={(ref: HTMLElement) => contentBody = ref} />
        </Content>
      </ContentWrapper>
    </ScrollWrapper>
  );
};

export default WebView;
