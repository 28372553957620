import 'crypto-js/core';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import Config from 'react-native-config';
import {getDeviceId, getModel, getVersion} from 'react-native-device-info';
import RNFetchBlob from 'rn-fetch-blob';
import {Platform} from 'react-native';
import {unzip} from 'react-native-zip-archive';
import label from './label';

const plansDir = `${RNFetchBlob.fs.dirs.DocumentDir}/plans`;

const serverUrl =
  Platform.OS === 'web' ? window.env.serverUrl : Config.SERVER_URL;

const apiKey =
  Platform.OS === 'web' ? window.env.apiKey : Config.API_KEY;

const apiSecret =
  Platform.OS === 'web' ? window.env.apiSecret : Config.API_SECRET;

export const contentUrl =
  Platform.OS === 'web'
    ? 'https://dc4kij462gn3d.cloudfront.net/ContentFile/Packages'
    : `file://${plansDir}`;

export const contentSignature =
  Platform.OS === 'web'
    ? '?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kYzRraWo0NjJnbjNkLmNsb3VkZnJvbnQubmV0LyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE2Mzk1OTI2ODV9fX1dfQ__&Key-Pair-Id=K1B0D3PKY57ZEX&Signature=IGkyBRUbTCPUP0J5HFkongGGnBDjOtoZLN-YxihzCo1WJnOLbL7xWpKT2sK5MinWe3EXQme6mY3o6RyM~LHlQifMCIysOWMwzmDQYOwwLjqWA0sLIB~WezXCXnE57Ip4Qyi04ilZVb2t9NWiRYED~jlZIPlYDhAH8NfzfkUhfbyN4JAAc2OsWJ3j8q5qTi9IQ7ncb0NLWXaDVxTe8vWoHbYM5qBkQGCCw~WB2VBrx2~Dtr3n4nSY-7oWD91tgFoKUXAETvcvfIcapj6A4L-o5zy8SfpZHsVH30ckOQ5X44qgkysDfs-FsMb2-b6mxqNSF8RiUGBQEWMjwWbtKhqsSg__'
    : '';

type PlanStatus = 'Publish' | 'Draft';

type Plan = {
  PlanID: string;
  Enabled: boolean;
  IsActive: boolean;
  Name: string;
  IconMediaID: string;
  Version: string; // map to number
  PublishedDate: string; // map to date
  PlanContentPackageID: string;
  PlanContentPackageSize: number;
  UpdateRequired: boolean;
  ContentOwner: string;
  PreviewSearchTerm: string;
  ExpiredDate: string; // map to date
  PlanStatus: PlanStatus;
  IsExpired: boolean;
};

export type PlanSearchResult = Plan & {
  OrganizationName: string;
  OrganizationSearchTerm: string;
  Description: string;
  SearchGroup: string;
  SearchTerm?: string;
  SearchTermPasswordRequired: boolean;
  PreviewSearchTermPasswordRequired: boolean;
};

export type PlanInfo = Plan & {
  NavBarBackgroundColor: string; // map to hex value
  NavBarTextColor: string; // map to hex value
  PlanDescription: string;
  Contacts: Contact[];
  SearchableTerm: string;
  Organization: string;
  Contents: Contents[];
};

export type Contact = {
  ContactID: string;
  Title: string;
  Name: string;
  Address1: string;
  Address2?: string;
  City: string;
  State: string;
  PostalCode: string;
  Phone1: string;
  Phone2?: string;
  Phone3?: string;
  Phone4?: string;
  Phone1Label: string;
  Phone2Label?: string;
  Phone3Label?: string;
  Phone4Label?: string;
  OtherInfo: string;
  CompanyName: string;
  Email: string;
};

export type MediaInfo = {
  MediaID: string;
  LastUpdateUtc: string;
  MediaUrl: string;
  MimeType: string;
  DownloadToken: string;
  Size: number;
  MetaData: null; // ??
  MediaName: string;
};

export type Contents =
  | ListContents
  | ExpandableContents
  | HtmlContents
  | FileContents;

export type ContentMeta = {
  Title: string;
  LastUpdate: string; // maps to date
};

export type ContentDisplay = {
  DisplayOrder: number;
  BackgroundColor: string; // map to hex value
  TextColor: string; // map to hex value
  Icon: null; // ??
};

type BaseContent = ContentMeta & {
  ContentID: string;
  IsRootContent: boolean;
  Type: 'L' | 'E' | 'H' | 'F' | 'A'; // List | Expandable | Html | File | Audio
};

export type ListContents = BaseContent & {
  ListContents: ListContent[];
};

export type ListContent = ContentDisplay &
  ContentMeta & {
    ParentPlanContentID: string;
    NextPlanContentID: string;
    ListContentID: string;
  };

export type ExpandableContents = BaseContent & {
  ExpandableContents: ExpandableContent[];
};

export type ExpandableContent = ContentDisplay &
  ContentMeta & {
    ExpandableContentID: string;
    ExpandableContentHTMLFile: string;
  };

export type HtmlContents = BaseContent & {
  IndexFile: string;
};

export type FileContents = BaseContent & {
  File: string;
};

type RequestMethod = 'GET' | 'POST';

const deviceInfo = () => {
  return {
    UserDeviceID: getDeviceId(),
    UserDeviceType: Platform.OS === 'ios' ? 'I' : 'A',
    UserDeviceOSVer: getVersion(),
    UserDeviceModelName: getModel(),
    UserDeviceCarrier: '',
    UserDeviceManufacture: Platform.OS === 'ios' ? 'Apple' : 'Google',
  };
};

class ServerError extends Error {}

const signedHeaders = (method: RequestMethod, url: string, body?: string) => {
  const timestamp = Date.now() / 1000;
  const salt = `${apiKey}${apiSecret}${timestamp}`;
  const message = `${method}${url}${salt}${body || ''}`;
  const signature: string = Base64.stringify(hmacSHA256(message, salt));

  return {
    'Content-Type': 'application/json',
    'Auth-Token': apiKey!,
    Timestamp: timestamp.toString(),
    Signature: signature,
  };
};

const jsonRequest = (
  method: RequestMethod,
  url: string,
  body?: string,
): Promise<any> => {
  const headers = signedHeaders(method, url, body);

  return fetch(
    Platform.OS === 'web' ? 'https://cors-anywhere.herokuapp.com/' + url : url,
    {
      // return fetch(url, {
      method,
      headers,
      body: body || null,
    },
  )
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.status === 'success') {
        return resp.data;
      } else {
        throw new ServerError(resp.message);
      }
    })
    .catch((e) => console.error(e));
};

const zipRequest = async (
  method: RequestMethod,
  url: string,
  body?: string,
): Promise<any> => {
  const headers = signedHeaders(method, url, body);

  try {
    // Download temp zip file
    const resp = await RNFetchBlob.config({
      fileCache: true,
    }).fetch(method, url, headers, body);
    // Check for errors
    if (resp.respInfo.status !== 200) {
      throw new ServerError(resp.data.message);
    }
    // Unzip file to documents directory
    const path = await unzip(resp.data, plansDir, 'UTF-8');
    // Remove temp zip file
    resp.flush();
    return path;
  } catch (e) {
    console.error(e);
  }
};

const api = {
  getInitialPlans: () => {
    return jsonRequest(
      'GET',
      `${serverUrl}/searchForInitialPlan?appName=${label.appName}`,
    ).then((resp) => resp.Plans);
  },
  getPlansByGroup: (group: string) => {
    return jsonRequest(
      'GET',
      `${serverUrl}/SearchByGroup?appName=${label.appName}&groupName=${group}`,
    ).then((resp) => resp.Plans);
  },
  searchPlans: (term: string) => {
    return jsonRequest(
      'GET',
      `${serverUrl}/search?appName=${
        label.appName
      }&keywords=${encodeURIComponent(term)}`,
    ).then((resp) => resp.Plans);
  },
  getVersions: (planIds: string[]) => {
    return jsonRequest(
      'POST',
      `${serverUrl}/Versions`,
      JSON.stringify({planIds: planIds.map((id: string) => ({PlanID: id}))}),
    ).then((resp) => resp.Plans);
  },
  getPlanInfo: (id: string, password: string = '') => {
    return jsonRequest(
      'GET',
      `${serverUrl}/PlanInfo?pid=${id}&password=${password}`,
    ).then((resp) => resp.Plan);
  },
  getPlanInfoByLicense: (license: string) => {
    return jsonRequest(
      'GET',
      `${serverUrl}/PlanInfoByLicense?license=${license}`,
    ).then((resp) => resp.Plan);
  },
  checkLicenseIsValid: (license: string) => {
    return jsonRequest(
      'GET',
      `${serverUrl}/IsLicenseActive?license=${license}`,
    );
  },
  getMediaInfo: (id: string) => {
    return jsonRequest('GET', `${serverUrl}/mediaInfo?mediaId=${id}`);
  },
  downloadMedia: (token: string) => {
    return zipRequest(
      'POST',
      `${serverUrl}/DownloadMedia`,
      JSON.stringify({
        DownloadToken: token,
        AppVersion: '1',
        AppID: '',
        ...deviceInfo(),
      }),
    );
  },
  downloadMediaByLicense: (
    firstName: string,
    lastName: string,
    email: string,
    key: string,
  ) => {
    return zipRequest(
      'POST',
      `${serverUrl}/DownloadMediaByLicense`,
      JSON.stringify({
        FirstName: firstName,
        LastName: lastName,
        EmailAddress: email,
        LicenseKey: key,
        AppVersion: '1',
        AppID: '',
        ...deviceInfo(),
      }),
    );
  },
};

export default api;
