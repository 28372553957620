import React from 'react';
import {TouchableOpacity, Alert} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useNavigation} from '@react-navigation/core';

interface DeletePlanButtonProps {
  planId: string;
  color?: string;
}

const DeletePlanButton = ({planId, color}: DeletePlanButtonProps) => {
  const navigator = useNavigation();

  const deletePlan = () => {
    const res = window.confirm('Are you sure you want to delete this plan?');
    if (res) {
      navigator.reset({
        routes: [
          {
            name: 'PlanList',
            params: {action: {type: 'delete plan', planIds: [planId]}},
          },
        ],
      });
    }
  };

  return (
    <TouchableOpacity onPress={deletePlan}>
      <Icon name="delete" size={30} color={color || 'white'} />
    </TouchableOpacity>
  );
};

export default DeletePlanButton;
