import React, {useContext} from 'react';
import {
  NativeSyntheticEvent,
  TextInputEndEditingEventData,
  Text,
} from 'react-native';
import styled from 'styled-components/native';
import PlanResult from './PlanResult';
import {RouteProp} from '@react-navigation/native';
import {SearchStackParamList} from './SearchRoutes';
import Screen from '../../styled-components/Screen';
import InputBox, {TextInput} from '../general/InputBox';
import PlansContext from '../../contexts/PlansContext';
import {useNavigation} from '@react-navigation/native';
import {Header} from '../../styled-components/Header';
import {windowIsWebDesktop, maxWidth} from '../../styled-components/windowIsWeb';

const Title = styled(Text)`
  width: 100%;
  margin: 0 0 15px;
  color: #333333;
  font-weight: bold;
`;

const ScreenPw = styled(Screen)`
  ${windowIsWebDesktop && `max-width: ${maxWidth.element};`}
`;

type SecureDownloadRouteProp = RouteProp<
  SearchStackParamList,
  'SecureDownload'
>;

interface SecureDownloadProps {
  route: SecureDownloadRouteProp;
}

const SecureDownload = ({route}: SecureDownloadProps) => {
  const {plan, returnRoute} = route.params;
  const navigator = useNavigation();
  const context = useContext(PlansContext);

  const downloadPlan = ({
    nativeEvent: {text},
  }: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
    context!.plans
      .download(plan.PlanID, text)
      .then(() => navigator.navigate(returnRoute))
      .catch((e) => context!.sendNotification('alert', e.message));
  };

  return (
    <>
      <Header>
        <PlanResult plan={plan} status={'ready'} />
      </Header>
      <ScreenPw>
        <Title>Please enter the password to download this plan</Title>
        <InputBox
          icon="lock"
          background="rgb(223, 230, 252)"
          color="rgb(97, 108, 146)">
          <TextInput
            autoCapitalize="none"
            textContentType="password"
            secureTextEntry={true}
            color="rgb(97, 108, 146)"
            placeholder="Password"
            placeholderTextColor="rgb(97, 108, 146)"
            autoFocus={true}
            autoCompleteType="off"
            onSubmitEditing={downloadPlan}
          />
        </InputBox>
      </ScreenPw>
    </>
  );
};

export default SecureDownload;
