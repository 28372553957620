import React, {Suspense} from 'react';
import TermsConditions from './components/TermsConditions';
import AsyncStorage from '@react-native-community/async-storage';
import Main from './components/Main';
import CenteredIndicator from './components/general/CenteredIndicator';
import {handlePending} from './utils/suspense';
import {StatusBar} from 'react-native';
import {ThemeProvider} from 'styled-components/native';
import startDatabase from './database/start';
import Config from 'react-native-config';
import label from './label/index';

console.log('Config', Config);
console.log('Environment', process.env);

const terms = handlePending(AsyncStorage.getItem('terms-accepted'));
const doa = handlePending(startDatabase());

const App = () => {
  return (
    <ThemeProvider theme={label.theme}>
      <StatusBar translucent={true} backgroundColor={label.theme.statusBar} />
      <Suspense fallback={<CenteredIndicator />}>
        <TermsConditions termsResource={terms}>
          <Main doaResource={doa} />
        </TermsConditions>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
