import React, {PropsWithChildren} from 'react';
import {
  TextInput as TextInputComponent,
  View,
  Text as TextComponent,
  ActivityIndicator,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import styled from 'styled-components/native';
import {windowIsWebDesktop, maxWidth} from '../../styled-components/windowIsWeb';

interface InputDisplayProps {
  background: string;
  color: string;
  webSize?: 'container'|'element';
}

const InputWrapper = styled(View)<InputDisplayProps>`
  flex-direction: row;
  width: 100%;
  padding: 0 15px;
  margin: 15px 0 0;
  border-radius: 10px;
  align-items: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  ${(props) => windowIsWebDesktop && props.webSize
    && (props.webSize === 'container' || props.webSize === 'element')
    ? `max-width: ${maxWidth[props.webSize]}`
    : `max-width: ${maxWidth.container}`
  };
`;

export const TextInput = styled(TextInputComponent)<{color?: string}>`
  flex-grow: 1;
  padding: 15px 0;
  color: ${(props) => props.color};
  ${windowIsWebDesktop && `outline-width: 0`}
`;

export const TextArea = styled(TextComponent)<{color: string}>`
  flex-grow: 1;
  padding: 15px 0;
  color: ${(props) => props.color};
`;

interface InputBoxProps extends PropsWithChildren<InputDisplayProps> {
  pending?: boolean;
  icon: string;
}

const InputBox = ({
  children,
  background,
  color,
  pending,
  icon,
  webSize,
}: InputBoxProps) => {
  return (
    <InputWrapper background={background} color={color} webSize={webSize}>
      {children}
      {pending ? (
        <ActivityIndicator color="white" />
      ) : (
        <Icon name={icon} size={24} color={color} />
      )}
    </InputWrapper>
  );
};

export default InputBox;
