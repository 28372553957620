import {ObjectSchema, Collection} from 'realm';
import {PlanInfo} from '../../api';
import {Contact, PhoneNumber} from './contact';
import {Contents, ExpandableContent, ListContent} from './content';

export class Plan {
  password?: string;
  planID: string;
  enabled: boolean;
  isActive: boolean;
  name: string;
  description?: string;
  organization?: string;
  iconMediaID?: string;
  version?: number;
  publishedDate: Date;
  planContentPackageID: string;
  planContentPackageSize?: number;
  updateRequired: boolean;
  contentOwner?: string;
  previewSearchTerm?: string;
  expiredDate?: Date;
  planStatus: string;
  isExpired: boolean;
  navBarBackgroundColor?: string;
  navBarTextColor?: string;
  planDescription?: string;
  contacts: Collection<Contact>;
  searchableTerm?: string;
  contents: Collection<Contents>;

  static schema: ObjectSchema = {
    name: 'Plan',
    primaryKey: 'planID',
    properties: {
      password: 'string?',
      planID: 'string',
      enabled: 'bool',
      isActive: 'bool',
      name: 'string',
      description: 'string?',
      organization: 'string?',
      iconMediaID: 'string?',
      version: 'float?',
      publishedDate: 'date',
      planContentPackageID: 'string',
      planContentPackageSize: 'int?',
      updateRequired: 'bool',
      contentOwner: 'string?',
      previewSearchTerm: 'string?',
      expiredDate: 'date?',
      planStatus: 'string',
      isExpired: 'bool',
      navBarBackgroundColor: 'string?',
      navBarTextColor: 'string?',
      planDescription: 'string?',
      contacts: 'Contact[]',
      searchableTerm: 'string?',
      contents: 'Contents[]',
    },
  };

  static apiToModel(data: PlanInfo, password: string = '') {
    return {
      password: password,
      planID: data.PlanID,
      enabled: data.Enabled,
      isActive: data.IsActive,
      name: data.Name,
      description: data.PlanDescription,
      organization: data.Organization,
      iconMediaID: data.IconMediaID,
      version: parseFloat(data.Version),
      publishedDate: new Date(data.PublishedDate),
      planContentPackageID: data.PlanContentPackageID,
      planContentPackageSize: data.PlanContentPackageSize,
      updateRequired: data.UpdateRequired,
      contentOwner: data.ContentOwner,
      previewSearchTerm: data.PreviewSearchTerm,
      expiredDate: new Date(data.ExpiredDate),
      planStatus: data.PlanStatus,
      isExpired: data.IsExpired,
      navBarBackgroundColor: data.NavBarBackgroundColor,
      navBarTextColor: data.NavBarTextColor,
      planDescription: data.PlanDescription,
      contacts: data.Contacts.map(Contact.apiToModel),
      searchableTerm: data.SearchableTerm,
      contents: data.Contents.map(Contents.apiToModel),
    };
  }
}

export default [
  Plan,
  Contact,
  PhoneNumber,
  Contents,
  ListContent,
  ExpandableContent,
];
