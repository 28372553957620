import React, {useContext} from 'react';
import {ScrollView} from '../general/ScrollView';
import Screen from '../../styled-components/Screen';
import {Plan} from '../../database/schemas/plan';
import PlanListItem from './PlanListItem';
import {useNavigation} from '@react-navigation/core';
import {NativeStackNavigationProp} from 'react-native-screens/lib/typescript/types';
import {PlanStackParamList} from './PlanRoutes';
import {ListItem} from '../../styled-components/List';
import {Header, Title} from '../../styled-components/Header';
import CenteredIndicator from '../general/CenteredIndicator';
// import {Download} from '../../hooks/usePlans';
import PlansContext from '../../contexts/PlansContext';

const PlanList = () => {
  const navigation = useNavigation<
    NativeStackNavigationProp<PlanStackParamList>
  >();
  const context = useContext(PlansContext);
  const unfiltered = context!.doa.getCollection<Plan>('Plan');
  // TODO: implement sorted and filtered
  const plans = unfiltered;
  // const downloaded = context!.plans.downloads
  //   .map((d: Download) => `planID != "${d.planId}"`)
  //   .join(' AND ');
  // const sorted = unfiltered.sorted('name');
  // const plans = context!.plans.downloads.length
  //   ? unfiltered.filtered(downloaded)
  //   : unfiltered;

  return (
    <>
      <Header>
        <Title>My Plans</Title>
      </Header>
      <ScrollView>
        <Screen>
          {context!.plans.deleting ? (
            <CenteredIndicator />
          ) : (
            <>
              {plans.map((plan: Plan) => {
                return (
                  <ListItem
                    key={plan.planID}
                    onPress={() =>
                      navigation.push('Content', {
                        planId: plan.planID,
                      })
                    }>
                    <PlanListItem plan={plan} />
                  </ListItem>
                );
              })}
            </>
          )}
        </Screen>
      </ScrollView>
    </>
  );
};

export default PlanList;
