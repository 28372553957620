import React, {useContext} from 'react';
import styled from 'styled-components/native';
import {RouteProp} from '@react-navigation/core';
import PlansContext from '../../../contexts/PlansContext';
import {PlanStackParamList} from '../PlanRoutes';
import ExpandableContent from './ExpandableContent';
import Pdf from 'react-native-pdf';
import ListContent from './ListContent';
import {Header, Title} from '../../../styled-components/Header';
import WebView from '../../general/WebView';
import {ScrollView} from '../../general/ScrollView';
import {Contents} from '../../../database/schemas/content';
import Audio from '../../general/Audio';
import {plansDir} from '../../../hooks/usePlans';
import {contentSignature, contentUrl} from '../../../api';

const PdfWrapper = styled(Pdf)`
  flex-grow: 1;
  width: 100%;
`;

type PlanRouteProp = RouteProp<PlanStackParamList, 'Content'>;

interface ContentProps {
  route: PlanRouteProp;
}

const Content = ({route: {params}}: ContentProps) => {
  const {planId, contentId} = params;
  const context = useContext(PlansContext);
  const headerColors = Contents.headerColors(context!.doa, contentId);
  const content = context!.doa.getContent(planId, contentId);

  if (!content) {
    return (
      <Header background={headerColors.background}>
        <Title color={headerColors.tint}>Coming Soon!</Title>
      </Header>
    );
  } else {
    return (
      <>
        <Header background={headerColors.background}>
          <Title color={headerColors.tint}>{content.title}</Title>
        </Header>
        {content.type === 'L' && (
          <ListContent planId={planId} contentId={content.contentID} />
        )}
        {content.type === 'E' && (
          <ExpandableContent planId={planId} contentId={content.contentID} />
        )}
        {content.type === 'H' && (
          <ScrollView>
            <WebView
              source={{
                uri: `${contentUrl}/${planId}/${
                  content.contentID
                }/${content.indexFile!}${contentSignature}`,
              }}
            />
          </ScrollView>
        )}
        {content.type === 'F' && (
          <PdfWrapper
            source={{
              uri: `${contentUrl}/${planId}/${
                content.contentID
              }/${content.file!}${contentSignature}`,
            }}
            fitPolicy={0}
            style={{
              backgroundColor: 'rgb(241, 243, 254)',
              paddingBottom: 100,
            }}
          />
        )}
        {content.type === 'A' && (
          <Audio
            source={{
              id: content.file!,
              title: content.title,
              artist: 'Artist',
              url: `file://${plansDir}/${planId}/${
                content.contentID
              }/${content.file!}`,
            }}
          />
        )}
      </>
    );
  }
};

export default Content;
